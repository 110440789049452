import { createSlice } from "@reduxjs/toolkit";
import {
	createUnit,
	deleteUnit,
	getUnits,
	updateUnit,
} from "../services/Units";

const SLICE_NAME = "unit";
const initialState = {
	loading: false,
	unit: {
		name: null,
		location: null,
	},
	units: [],
	totalUnits: 0,
	filter: {
		q: {},
	},
};

export const unitSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[getUnits.pending]: (state) => {
			state.loading = true;
		},
		[getUnits.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.units = payload.data;
			state.totalUnits = payload.total;
		},
		[createUnit.pending]: (state) => {
			state.loading = true;
		},
		[createUnit.fulfilled]: (state, { payload }) => {
			state.units.push(payload);
			state.loading = false;
		},

		[updateUnit.pending]: (state) => {
			state.loading = true;
		},
		[updateUnit.fulfilled]: (state, { payload }) => {
			const index = state.units.findIndex((unt) => unt.id === payload.id);
			if (index !== -1) {
				state.units[index] = payload;
			}
		},
		[deleteUnit.pending]: (state) => {
			state.loading = true;
		},
		[deleteUnit.fulfilled]: (state, { payload }) => {
			state.units = state.units.filter((unit) => unit.id !== parseInt(payload));
		},
	},
});

export const {
	setFilter,
	setRoleFilter,
	setCompanyFilter,
	setStatusFilter,
	setLastActiveFilter,
	emptySearchField,
	setSelectedUser,
	clearFilter,
	setIsInfo,
	setEditModalOpen,
	setDeleteModalOpen,
	clearFormErrors,
	setUpdateUser,
	clearPasswordReset,
} = unitSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const unitSliceSelectors = {
	loading: (rootState) => {
		const appState = getAppState(rootState);
		return appState.loading;
	},
	units: (rootState) => {
		const appState = getAppState(rootState);
		return appState.units;
	},
	totalUnits: (rootState) => {
		const appState = getAppState(rootState);
		return appState.totalUnits;
	},
};
