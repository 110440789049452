import {
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import UserInputField from "../../components/inputs/UserInputField";
import { createInvoice, updateInvoice } from "../../services/Invoice";
import { invoiceSliceSelectors } from "../../slices/InvoiceSlice";
import { userSliceSelectors } from "../../slices/UserSlice";

const InvoiceEdit = (props) => {
	const navigate = useNavigate();
	const [type, setType] = useState("");
	const [invoice, setInvoice] = useState();
	const [buyer, setBuyer] = useState(null);
	const invoices = useSelector(invoiceSliceSelectors.invoices);
	const user = useSelector(userSliceSelectors.defaultUserFields);
	const users = useSelector(userSliceSelectors.users);
	const { id } = useParams();
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({});

	useEffect(() => {
		if (id && !invoice && invoices) {
			const foundInvoice = invoices.find(
				(invoice) => invoice.id === parseInt(id)
			);
			if (foundInvoice) {
				setInvoice(foundInvoice);
				setType(foundInvoice.type);
				reset(foundInvoice);
			}
		}
	}, [invoice, id, invoices, reset]);

	const onSubmit = (values) => {
		if (id) {
			dispatch(updateInvoice({ id, payload: values })).then(() => {
				navigate("/transactions");
			});
		} else {
			dispatch(
				createInvoice({
					...values,
					seller_id: user.id,
					user_id: buyer.id,
					unit_id: null,
				})
			).then(() => {
				navigate("/transactions");
			});
		}
	};
	const handleChange = (e) => {
		setType(e.target.value);
	};

	const ref = useRef("");
	const handleSetBuyer = (val) => {
		setBuyer(users.find(({ id }) => id === val));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div
				style={{
					padding: 30,
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					display: "flex",
					height: "auto",
					paddingTop: 100,
				}}>
				<Grid style={{ marginBottom: 50 }}>
					<Typography variant="h4" color="primary">
						Kreiraj Priliv/Odliv
					</Typography>
				</Grid>

				<Paper
					variant="outlined"
					style={{ width: 800, paddingTop: 30, paddingBottom: 30 }}>
					<Grid
						container
						spacing={3}
						direction={"column"}
						justifyContent={"center"}
						alignItems={"center"}>
						<Typography variant="h6">Please enter your credentials</Typography>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-helper-label">
									Izaberi tip transakcije(Priliv/Odliv)
								</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									style={{ width: 250 }}
									{...register("type")}
									label="Tip transakcije"
									ref={ref}
									onChange={handleChange}
									value={type}
									fullWidth
									error={Boolean(errors.type)}>
									<MenuItem value={"income"}>{"Priliv"}</MenuItem>
									<MenuItem value={"outcome"}>{"Odliv"}</MenuItem>
								</Select>

								{errors.type && (
									<Typography color="error" variant="subtitle2">
										{errors.type.message}
									</Typography>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register("number")}
								style={{ width: 250 }}
								label="Broj racuna/predracuna"
								error={Boolean(errors.number)}
							/>
						</Grid>
						<Grid item xs={12}>
							<UserInputField
								userType={3}
								label={"Kupac"}
								style={{ width: 250 }}
								isInvoice={true}
								hanldeChange={handleSetBuyer}
							/>
							{buyer ? (
								<Typography variant="h6">
									Balance:{" "}
									{buyer.vip_card_balance
										? Math.round(buyer.vip_card_balance)
										: 0}
								</Typography>
							) : null}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">
								Lokacija: {user.location.name}, {user.location.location}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register("date")}
								label="Datum racuna (yyyy-mm-dd)"
								error={Boolean(errors.date)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...register("amount")}
								label="Iznos racuna"
								error={Boolean(errors.email)}
							/>
						</Grid>
						{type === "outcome" ? (
							<Grid item xs={12}>
								<TextField
									{...register("discount")}
									label="Bodovi"
									error={Boolean(errors.discount)}
								/>
							</Grid>
						) : (
							<Grid item xs={12}>
								<TextField
									{...register("buyer_discount")}
									label="Popust krajnjem kupcu x%"
									error={Boolean(errors.buyer_discount)}
								/>
							</Grid>
						)}

						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								color="primary"
								variant="outlined">
								Save
							</Button>
							<Button
								onClick={() => {
									navigate(`/transactions`);
								}}
								fullWidth
								variant="outlined">
								Back
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		</form>
	);
};
export default InvoiceEdit;
