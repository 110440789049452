export const getUserTypes = (loggedInUser) => {
	const { type } = loggedInUser;
	const typeAdmin = {
		name: "Admin",
		value: 1,
	};
	const typeSeller = {
		name: "Prodavac",
		value: 2,
	};
	const typeBuyer = {
		name: "Kupac",
		value: 3,
	};
	switch (type) {
		case 1:
			return [typeAdmin, typeSeller, typeBuyer];
		default:
			return [typeBuyer];
	}
};
