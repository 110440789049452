/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { turnOffNotifications } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles({
	snackbar: {
		position: "unset",
		transform: "none",
		minWidth: "350px",
		maxWidth: "500px",
		margin: "2px 0 2px 0",
	},
	alert: {
		width: "100%",
	},
});

export function GlobalToastNotificationItem({ onClose, notification }) {
	const dispatch = useDispatch();
	const { uuid, type, title, message, visibilityTime } = notification;
	const classes = useStyles();
	const [open, setOpen] = useState(true);

	const close = (event, reason) => {
		if (reason === "clickaway") return;

		setOpen(false);
		// Allow snackbar to fade out before removing this component.
		setTimeout(() => onClose(uuid), 200);
	};

	useEffect(() => () => dispatch(turnOffNotifications()), []);

	return (
		<Snackbar
			className={classes.snackbar}
			key={uuid}
			open={open}
			autoHideDuration={visibilityTime}
			onClose={close}>
			<Alert
				onClose={close}
				severity={type}
				variant="filled"
				className={classes.alert}>
				{title && <AlertTitle>{title}</AlertTitle>}
				{typeof message === "string" ? (
					<Typography variant="body3">{message}</Typography>
				) : (
					message.map((msg) => (
						<Typography variant="body3" display="block">
							{msg}
						</Typography>
					))
				)}
			</Alert>
		</Snackbar>
	);
}
