import { createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../slices/NotificationSlice/GlobalNotificationSlice";
import { axiosInstance } from "./api";
// import { bootstrapAction } from "../../../../actions/bootstrap";

export const getUsers = createAsyncThunk("users", async (props) => {
	const response = await axiosInstance.get("/users", { params: { ...props } });
	return response.data;
});

export const createUser = createAsyncThunk(
	"createUser",
	async (props, { dispatch, rejectWithValue }) => {
		try {
			const response = await axiosInstance.post("/users", props);
			if (response.data) {
				dispatch(
					addMessage({
						type: "success",
						title: "Korisnik dodat!",
						visibilityTime: 5000,
					})
				);
			}

			return response.data;
		} catch (e) {
			const { response } = e;
			const { message } = response.data;
			dispatch(
				addMessage({
					type: "error",
					title: "Korisnik nije dodat",
					message: message,
					visibilityTime: 5000,
				})
			);
		}
	}
);

export const editUser = createAsyncThunk(
	"editUser",
	async ({ id, payload }, { dispatch }) => {
		const response = await axiosInstance.put(`/users/${id}`, payload);
		return response.data;
	}
);

export const deleteUser = createAsyncThunk(
	"deleteUser",
	async (id, { dispatch }) => {
		await axiosInstance.delete(`/users/${id}`);
		return id;
	}
);
