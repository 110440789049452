import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { filterUnitsByUserUnit } from "../../helpers/units";
import { getUserTypes } from "../../helpers/user";
import { createUser, editUser } from "../../services/UserActions";
import { unitSliceSelectors } from "../../slices/UnitSlice";
import { userSliceSelectors } from "../../slices/UserSlice";
import { userCreateSchema, userEditSchema } from "../../validation/userSchema";

const UserEdit = (props) => {
	const navigate = useNavigate();
	const [userType, setUserType] = useState("");
	const [unit, setUnit] = useState("");
	const loggedInUser = useSelector(userSliceSelectors.defaultUserFields);
	const [user, setUser] = useState(null);
	const users = useSelector(userSliceSelectors.users);
	const units = useSelector(unitSliceSelectors.units);
	const { id } = useParams();
	const dispatch = useDispatch();
	const methods = useForm({
		resolver: yupResolver(id ? userEditSchema : userCreateSchema),
	});
	console.log(loggedInUser, "here");
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = methods;

	useEffect(() => {
		if (id && !user && users) {
			const foundUser = users.find((user) => user.id === parseInt(id));
			if (foundUser) {
				setUser(foundUser);
				setUserType(foundUser.type);
				setUnit(foundUser.unit_id);
				reset(foundUser);
			}
		}
	}, [user, users, id, reset]);

	const onSubmit = (values) => {
		if (id) {
			dispatch(editUser({ id, payload: values })).then(() => {
				navigate("/");
			});
		} else {
			dispatch(createUser(values)).then(() => {
				navigate("/");
			});
		}
	};
	const handleChange = (e) => {
		setUserType(e.target.value);
	};
	const handleUnitChange = (e) => {
		setUnit(e.target.value);
	};
	const ref = useRef("");
	const ref2 = useRef("");
	const requiresPassword = () => {
		if (userType === 1 || userType === 2) {
			return true;
		}

		return false;
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div
					style={{
						padding: 30,
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						display: "flex",
						height: "auto",
						paddingTop: 100,
					}}>
					<Grid style={{ marginBottom: 50 }}>
						<Typography variant="h4" color="primary">
							Kreiraj korisnika
						</Typography>
					</Grid>

					<Paper
						variant="outlined"
						style={{
							width: 600,
							paddingTop: 30,
							paddingBottom: 30,
							paddingLeft: 100,
							paddingRight: 100,
						}}>
						<Grid
							container
							spacing={3}
							rowSpacing={1}
							justifyContent={"center"}
							direction="column"
							alignItems="strech"
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							zeroMinWidth>
							<Typography variant="h6">
								Please enter your credentials
							</Typography>
							<Grid item xs={12}>
								<Box>
									<FormControl style={{ width: "100%" }}>
										<InputLabel id="demo-simple-select-helper-label">
											Izaberi tip korisnika
										</InputLabel>
										<Select
											labelId="demo-simple-select-helper-label"
											{...register("type")}
											label="Tip Korisnika"
											ref={ref}
											fullWidth
											onChange={handleChange}
											value={userType}
											error={Boolean(errors.type)}>
											{getUserTypes(loggedInUser).map((type) => {
												return (
													<MenuItem value={type.value}>{type.name}</MenuItem>
												);
											})}
										</Select>

										{errors.type && (
											<Typography color="error" variant="subtitle2">
												{errors.type.message}
											</Typography>
										)}
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<FormControl style={{ width: "100%" }}>
									<InputLabel id="unit_id_helper">Izaberi lokaciju</InputLabel>
									<Select
										labelId="unit_id_helper"
										{...register("unit_id")}
										label="Lokacija"
										ref={ref2}
										fullWidth
										onChange={handleUnitChange}
										value={unit}
										error={Boolean(errors.unit_id)}>
										{filterUnitsByUserUnit(units, loggedInUser).map((unit) => {
											return (
												<MenuItem value={unit.id}>
													{unit.name}, {unit.location}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Box>
									<TextField
										{...register("first_name")}
										label="Ime"
										fullWidth
										error={Boolean(errors.first_name)}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box>
									<TextField
										{...register("last_name")}
										label="Prezime"
										fullWidth
										error={Boolean(errors.first_name)}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box>
									<TextField
										{...register("username")}
										label="Username"
										fullWidth
										error={Boolean(errors.username)}
									/>
									{errors.username && (
										<Typography color="error" variant="subtitle2">
											{errors.username.message}
										</Typography>
									)}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box>
									<TextField
										{...register("discount")}
										label="Popust u %"
										fullWidth
										error={Boolean(errors.discount)}
									/>
									{errors.discount && (
										<Typography color="error" variant="subtitle2">
											{errors.discount.message}
										</Typography>
									)}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box>
									<TextField
										{...register("email")}
										label="Email"
										fullWidth
										error={Boolean(errors.email)}
									/>
								</Box>
							</Grid>
							{requiresPassword() && !id ? (
								<Grid item xs={12}>
									<Box>
										<TextField
											{...register("password")}
											label="Password"
											type={"password"}
											fullWidth
											error={Boolean(errors.password)}
										/>
										{errors.password && (
											<Typography color="error" variant="subtitle2">
												{errors.password.message}
											</Typography>
										)}
									</Box>
								</Grid>
							) : null}
							{requiresPassword() && !id ? (
								<Grid item xs={12}>
									<Box>
										<TextField
											{...register("confirm_password")}
											label="Confirm Password"
											type={"password"}
											fullWidth
											error={Boolean(errors.confirm_password)}
										/>
										{errors.confirm_password && (
											<Typography color="error" variant="subtitle2">
												{errors.confirm_password.message}
											</Typography>
										)}
									</Box>
								</Grid>
							) : null}
							<Grid item xs={12}>
								<Button
									type="submit"
									fullWidth
									color="primary"
									variant="outlined">
									Save
								</Button>
								<Button
									onClick={() => {
										navigate(`/users`);
									}}
									fullWidth
									variant="outlined">
									Back
								</Button>
							</Grid>
						</Grid>
					</Paper>
				</div>
			</form>
		</FormProvider>
	);
};
export default UserEdit;
