import * as React from "react";
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { useNavigate } from "react-router";
import CustomTablePagination from "./TablePagination";

const DataTable = ({ columns, rows, handlePageChange, numberOfRows }) => {
	const navigate = useNavigate();
	const getTableRow = (row, index) => {
		const keys = Object.keys(row);
		return (
			<TableRow key={index}>
				{keys.map((key, index2) => {
					if (key === "actions") {
						return (
							<TableCell key={`cell-${row.id}-${index2}`}>
								{row[key].map((action) => {
									if (action.type && action.type === "delete") {
										return (
											<Button color="secondary" onClick={action.action}>
												{action.name}
											</Button>
										);
									} else {
										return (
											<Button onClick={() => navigate(action.path)}>
												{action.name}
											</Button>
										);
									}
								})}
							</TableCell>
						);
					}
					return <TableCell>{row[key]}</TableCell>;
				})}
			</TableRow>
		);
	};
	return (
		<div style={{ width: "100%" }}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						{columns.map((column) => {
							return <TableCell>{column.headerName}</TableCell>;
						})}
					</TableHead>
					<TableBody>
						{rows ? rows.map((row, index) => getTableRow(row, index)) : null}
					</TableBody>
				</Table>
				<CustomTablePagination
					handlePageChange={handlePageChange}
					numberOfRows={numberOfRows}
				/>
			</TableContainer>
		</div>
	);
};
export default DataTable;
