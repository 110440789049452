import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserFilter from "../../components/filters/UserFilter";
import DataTable from "../../components/Table";
import { deleteUser, getUsers } from "../../services/UserActions";
import { userSliceSelectors } from "../../slices/UserSlice";

const UsersList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const columns = [
		{
			field: "first_name",
			headerName: "Ime",
			width: 150,
			editable: true,
		},
		{
			field: "last_name",
			headerName: "Prezime",
			width: 150,
			editable: false,
		},
		{
			field: "username",
			headerName: "Username",
			width: 150,
			editable: true,
		},
		{
			field: "location",
			headerName: "Lokacija",
			width: 150,
			editable: true,
		},
		{
			field: "email",
			headerName: "Email",
			width: 150,
			editable: true,
		},
		{
			field: "type",
			headerName: "Tip",
			width: 150,
			editable: true,
		},
		{
			field: "vip_card_number",
			headerName: "Vip kartica",
			description: "This column has a value getter and is not sortable.",
			width: 160,
		},
		{
			field: "discount",
			headerName: "Popust",
			description: "This column has a value getter and is not sortable.",
			width: 160,
		},
		{
			field: "vip_card_balance",
			headerName: "Balans vip kartice",
			description: "This column has a value getter and is not sortable.",
			width: 160,
		},
		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 160,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => {
								navigate(`/users/${params.id}/edit`);
							}}>
							{" "}
							Edit{" "}
						</Button>
					</>
				);
			},
		},
	];
	const handleSearchChange = (e) => {
		dispatch(getUsers({ q: { name: e.target.value } }));
	};

	const handleSearchChangeByVipCard = (e) => {
		dispatch(getUsers({ q: { vip_card_number: e.target.value } }));
	};

	const handlePageChange = (pageNumber) => {
		dispatch(getUsers({ page: pageNumber + 1 }));
	};

	const rows = useSelector(userSliceSelectors.users);
	const totalUsers = useSelector(userSliceSelectors.totalUsers);
	return (
		<Grid container spacing={5}>
			<Grid item xs={2}>
				<UserFilter
					handleSearchChange={handleSearchChange}
					handleSearchChangeByVipCard={handleSearchChangeByVipCard}
				/>
			</Grid>
			<Grid item xs={10}>
				<Grid container>
					<Grid item xs={12} style={{ display: "flex" }}>
						<Button
							variant="outlined"
							onClick={() => {
								navigate("/users/create");
							}}
							style={{ marginLeft: "auto" }}
							color="primary">
							Dodaj koirsnika
						</Button>
					</Grid>
					<Grid item xs={12}>
						<DataTable
							handlePageChange={handlePageChange}
							numberOfRows={totalUsers}
							rows={
								rows
									? rows.map(
											({
												id,
												first_name,
												last_name,
												email,
												type,
												username,
												vip_card_number,
												discount,
												vip_card_balance,
												location,
											}) => ({
												first_name,
												last_name,
												username,
												location: location?.name,
												email,
												type:
													type === 1
														? "Admin"
														: type === 2
														? "Prodavac"
														: "Kupac",
												vip_card_number,
												discount: discount ? `${discount} %` : null,
												vip_card_balance: Math.round(vip_card_balance) + " RSD",
												actions: [
													{ name: "Edit", path: `/users/${id}/edit` },
													{
														name: "Delete",
														type: "delete",
														action: () => {
															dispatch(deleteUser(id));
														},
													},
												],
											})
									  )
									: []
							}
							columns={columns}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UsersList;
