/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoiceFilter from "../../components/filters/InvoiceFilter";
import DataTable from "../../components/Table";
import { deleteInvoice, getInvoices } from "../../services/Invoice";
import { invoiceSliceSelectors } from "../../slices/InvoiceSlice";

const Transactions = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const columns = [
		{
			field: "user.first_name",
			headerName: "Ime Kupca",
			width: 150,
			editable: true,
		},
		{
			field: "user.last_name",
			headerName: "Prezime Kupca",
			width: 150,
			editable: false,
		},
		{
			field: "user.vip_card_number",
			headerName: "Vip Kartica Kupca",
			width: 150,
			editable: false,
		},
		{
			field: "user.username",
			headerName: "Nick Kupca",
			width: 150,
			editable: false,
		},
		{
			field: "seller.first_name",
			headerName: "Ime Prodavca",
			width: 150,
			editable: true,
		},
		{
			field: "seller.last_name",
			headerName: "Prezime Prodavca",
			width: 150,
			editable: false,
		},
		{
			field: "seller.username",
			headerName: "Nick Prodavca",
			width: 150,
			editable: true,
		},

		{
			field: "unit.name",
			headerName: "Ime Poslovnice",
			width: 150,
			editable: true,
		},
		{
			field: "unit.location",
			headerName: "Lokacija Poslovnice",
			width: 150,
			editable: true,
		},

		{
			field: "discount",
			headerName: "Poeni",
			width: 200,
			editable: true,
		},

		{
			field: "type",
			headerName: "Tip transakcije",
			width: 150,
			editable: true,
		},

		{
			field: "number",
			headerName: "Broj racuna",
			width: 150,
			editable: true,
		},

		{
			field: "date",
			headerName: "Datum na racunu",
			width: 150,
			editable: true,
		},

		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 160,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => {
								navigate(`/transactions/${params.id}/edit`);
							}}>
							{" "}
							Edit{" "}
						</Button>
					</>
				);
			},
		},
	];

	const handleSearchChange = (values) => {
		console.log("HERE", values);
		dispatch(getInvoices(values));
	};

	const handlePageChange = (pageNumber) => {
		dispatch(getInvoices({ page: pageNumber + 1 }));
	};

	const rows = useSelector(invoiceSliceSelectors.invoices);
	const totalInvoices = useSelector(invoiceSliceSelectors.totalInvoices);
	useEffect(() => {
		dispatch(getInvoices());
	}, []);
	return (
		<Grid container spacing={5}>
			<Grid item xs={2}>
				<InvoiceFilter hanldeChange={handleSearchChange} />
			</Grid>
			<Grid item xs={10}>
				<Grid container>
					<Grid item xs={12} style={{ display: "flex" }}>
						<Button
							variant="outlined"
							onClick={() => {
								navigate("/transactions/create");
							}}
							style={{ marginLeft: "auto" }}
							color="primary">
							Dodaj Priliv/Odliv
						</Button>
					</Grid>
					<Grid item xs={12}>
						<DataTable
							handlePageChange={handlePageChange}
							numberOfRows={totalInvoices}
							rows={
								rows
									? rows.map(
											({
												id,
												user,
												seller,
												unit,
												amount,
												discount,
												type,
												number,
												date,
											}) => ({
												"user.first_name": user.first_name,
												"user.last_name": user.last_name,
												"user.vip_card_number": user.vip_card_number,
												"user.username": user.username,
												"seller.first_name": seller.first_name,
												"seller.last_name": seller.last_name,
												"seller.username": seller.username,
												"unit.name": unit.name,
												"unit.location": unit.location,
												discount: Math.round(discount) + " RSD",
												type: type === "income" ? "Priliv" : "Odliv",
												number,
												date,
												actions: [
													{
														name: "Delete",
														type: "delete",
														action: () => {
															dispatch(deleteInvoice(id));
														},
													},
												],
											})
									  )
									: []
							}
							columns={columns}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Transactions;
