import * as React from "react";
import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { unitSliceSelectors } from "../../slices/UnitSlice";
import { getUnits } from "../../services/Units";
import { ConnectForm } from "../ConnectForm";

const UnitInputField = (props) => {
	const { hanldeChange, style, connectForm } = props;
	const dispatch = useDispatch();
	const units = useSelector(unitSliceSelectors.units);

	const handleUnitInputChange = async (e) => {
		if (e) {
			dispatch(
				getUnits({
					q: {
						name: e.target.value,
					},
					type: 3,
				})
			);
		}
	};

	const handleUnitSelectedChange = (e, value) => {
		if (!value) {
			return;
		}
		const { id } = value;

		hanldeChange(id);
	};

	return connectForm ? (
		<ConnectForm>
			{({ register, formState: { errors }, setValue, getValues }) => {
				console.log("unit_id", getValues("unit_id"));
				console.log(
					"UNIT",
					units.find((item) => item.id === getValues("unit_id"))
				);
				return (
					<>
						<Autocomplete
							id="combo-box-demo"
							{...register("unit_id")}
							options={units}
							value={
								getValues("unit_id")
									? units.find((item) => item.id === getValues("unit_id"))
									: null
							}
							getOptionLabel={(option) => `${option.name}  ${option.location}`}
							onInputChange={handleUnitInputChange}
							onChange={(e, val) => {
								if (hanldeChange !== undefined && val) {
									handleUnitSelectedChange(val);
								} else {
									console.log("here", val);
									if (val) {
										setValue("unit_id", val.id);
									} else {
										setValue("unit_id", null);
									}
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={"Lokacija"}
									variant="outlined"
									fullWidth
									style={style}
									size="medium"
								/>
							)}
						/>

						{errors.unit_id && (
							<Typography color="error" variant="subtitle2">
								{errors.unit_id.message}
							</Typography>
						)}
					</>
				);
			}}
		</ConnectForm>
	) : (
		<Autocomplete
			id="combo-box-demo"
			options={units}
			getOptionLabel={(option) => `${option.name}  ${option.location}`}
			onInputChange={handleUnitInputChange}
			onChange={handleUnitSelectedChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label={"Lokacija"}
					variant="outlined"
					fullWidth
					style={style}
					size="medium"
				/>
			)}
		/>
	);
};

export default UnitInputField;
