import { createAsyncThunk } from "@reduxjs/toolkit";
// import { bootstrapAction } from "../../../../actions/bootstrap";
import { axiosInstance } from "../api";

export const getMe = createAsyncThunk("me", async () => {
	const response = await axiosInstance.get("/me");
	return response.data;
});

export const logIn = createAsyncThunk("login", async (props, { dispatch }) => {
	const response = await axiosInstance.post("/authenticate", {
		username: props[0],
		password: props[1],
	});

	return response.data;
});

export const logOut = createAsyncThunk("logout", async () => {
	return {};
});
