import axios from "axios";
import Qs from "qs";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	paramsSerializer(params) {
		return Qs.stringify(params, { arrayFormat: "brackets" });
	},
});

axiosInstance.interceptors.request.use((config) => {
	const token = localStorage.getItem("token");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (String(error.response.status).match(/^5[0-9]{2}$/g)) {
			error.response.data = {
				error: {
					message: "Internal server error. Please contact your administrator!",
				},
			};
		} else if (error.response.status === 401) {
			localStorage.removeItem("token");
		}

		return Promise.reject(error);
	}
);
