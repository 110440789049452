import {
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { logIn } from "../services/user/LoginActions";
import { useDispatch, useSelector } from "react-redux";
import { userLoginSchema } from "../validation/userSchema";
import { userSliceSelectors } from "../slices/UserSlice";

export default function Login() {
	const dispatch = useDispatch();
	const loginError = useSelector(userSliceSelectors.loginError);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		resolver: yupResolver(userLoginSchema),
	});

	useEffect(() => {
		if (loginError) {
			setError("username", { message: "Invalid credentials", type: "manual" });
			setError("password", { message: "Invalid credentials", type: "manual" });
		}
	}, [loginError, setError]);

	const onSubmit = (data) => {
		dispatch(logIn([data.username, data.password]));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div
				style={{
					padding: 30,
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					display: "flex",
					height: "auto",
					paddingTop: 100,
				}}>
				<Grid style={{ marginBottom: 50 }}>
					<Typography variant="h4" color="primary">
						Central CH Loyalty APP
					</Typography>
				</Grid>

				<Paper
					elevation={24}
					style={{ width: 400, paddingTop: 30, paddingBottom: 30 }}>
					<Grid
						container
						spacing={3}
						direction={"column"}
						justify={"center"}
						alignItems={"center"}>
						<Typography variant="h6">Please enter your credentials</Typography>
						<Grid item xs={12}>
							<Box>
								<TextField
									{...register("username")}
									label="Username"
									error={Boolean(errors.username)}
								/>
								{errors.username && (
									<Typography color="error" variant="subtitle2">
										{errors.username.message}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box>
								<TextField
									{...register("password")}
									label="Password"
									type={"password"}
									error={Boolean(errors.password)}
								/>
								{errors.password && (
									<Typography color="error" variant="subtitle2">
										{errors.password.message}
									</Typography>
								)}
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								color="primary"
								variant="outlined">
								Login
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		</form>
	);
}
