import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UnitFilter from "../../components/filters/UnitFilter";
import DataTable from "../../components/Table";
import { deleteUnit, getUnits } from "../../services/Units";
import { unitSliceSelectors } from "../../slices/UnitSlice";

const UnitList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const columns = [
		{
			field: "name",
			headerName: "Ime poslovnice",
			width: 150,
			editable: true,
		},
		{
			field: "location",
			headerName: "Lokacija",
			width: 150,
			editable: false,
		},
		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 160,
			renderCell: (params) => {
				return (
					<>
						<Button
							onClick={() => {
								navigate(`/units/${params.id}/edit`);
							}}>
							{" "}
							Edit{" "}
						</Button>
					</>
				);
			},
		},
	];

	const handleSearchChange = (e) => {
		dispatch(getUnits({ q: { name: e.target.value } }));
	};

	const handlePageChange = (pageNumber) => {
		dispatch(getUnits({ page: pageNumber + 1 }));
	};

	const rows = useSelector(unitSliceSelectors.units);
	const totalUnits = useSelector(unitSliceSelectors.totalUnits);
	return (
		<Grid container spacing={5}>
			<Grid item xs={2}>
				<UnitFilter handleSearchChange={handleSearchChange} />
			</Grid>
			<Grid item xs={10}>
				<Grid container>
					<Grid item xs={12} style={{ display: "flex" }}>
						<Button
							variant="outlined"
							onClick={() => navigate("/units/create")}
							color="primary"
							style={{ marginLeft: "auto" }}>
							Dodaj poslovnicu
						</Button>
					</Grid>
					<Grid item xs={12}>
						<DataTable
							handlePageChange={handlePageChange}
							numberOfRows={totalUnits}
							rows={
								rows
									? rows.map(({ id, name, location }) => ({
											name,
											location,
											actions: [
												{ name: "Edit", path: `/units/${id}/edit` },
												{
													name: "Delete",
													type: "delete",
													action: () => {
														dispatch(deleteUnit(id));
													},
												},
											],
									  }))
									: []
							}
							columns={columns}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UnitList;
