import * as React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

import UnitInputField from "../inputs/UnitInputField";
import UserInputField from "../inputs/UserInputField";
import { useSelector } from "react-redux";
import { userSliceSelectors } from "../../slices/UserSlice";

const InvoiceFilter = ({ hanldeChange }) => {
	const user = useSelector(userSliceSelectors.defaultUserFields);
	const [filter, setFilter] = React.useState({
		seller_id: null,
		user_id: null,
		unit_id: user.type !== 1 ? user.unit_id : null,
	});

	const removeNullProperties = (values) => {
		const filters = {};
		Object.keys(values).forEach((key) => {
			if (values[key] !== null) {
				filters[key] = values[key];
			}
		});

		return filters;
	};
	const handleUserSelectedChange = (value) => {
		if (!value) {
			return;
		}
		const { id, type } = value;
		let data = {};
		if (type === 2 || type === 1) {
			data = { ...filter, seller_id: id };
		} else {
			data = { ...filter, user_id: id };
		}
		hanldeChange(removeNullProperties(data));
		setFilter(data);
	};

	const handleUnitSelectedChange = (id) => {
		const data = { ...filter, unit_id: id };

		hanldeChange(removeNullProperties(data));
		setFilter(data);
	};

	return (
		<Paper variant="outlined" style={{ padding: 20, marginTop: 36.5 }}>
			<Typography variant="h6" style={{ marginBottom: 5 }}>
				Filter
			</Typography>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<UserInputField
						userType={3}
						hanldeChange={(id) => {
							handleUserSelectedChange({ id, type: 3 });
						}}
						title="Kupac"
					/>
				</Grid>
				<Grid item xs={12}>
					<UserInputField
						userType={2}
						hanldeChange={(id) => {
							handleUserSelectedChange({ id, type: 2 });
						}}
						title="Prodavac"
					/>
				</Grid>
				{user.type === 1 ? (
					<Grid item xs={12}>
						<UnitInputField
							hanldeChange={handleUnitSelectedChange}
							connectForm={false}
						/>
					</Grid>
				) : null}
			</Grid>
		</Paper>
	);
};

export default InvoiceFilter;
