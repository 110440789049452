import React, { useEffect } from "react";
import Layout from "./Layout";
import Home from "../pages/Home";
import UserEdit from "../pages/Users/Add";
import Login from "../pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { userSliceSelectors } from "../slices/UserSlice";
import { getMe } from "../services/user/LoginActions";
import UnitList from "../pages/Units/List";
import UsersList from "../pages/Users/List";
import CreateUnit from "../pages/Units/Add";
import Transactions from "../pages/Transactions/Transactions";
import InvoiceEdit from "../pages/Transactions/Add";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function AuthorizedRoute(props) {
	return <Layout {...props} />;
}
const AppRouter = () => {
	const dispatch = useDispatch();
	// Check whether user exists in state, otherwise load it
	const { id } = useSelector(userSliceSelectors.defaultUserFields);
	const loading = useSelector(userSliceSelectors.loading);
	const token = localStorage.getItem("token");

	useEffect(() => {
		if (!id && !loading && token !== null) {
			dispatch(getMe());
		}
	}, [id, loading, token, dispatch]);

	if (!token) {
		return (
			<Router>
				<Routes>
					<Route path="/" element={<Login />} />
				</Routes>
			</Router>
		);
	}
	return (
		<Router>
			<Routes>
				<Route path="/" element={<AuthorizedRoute component={Home} />} />
				<Route
					path="/units"
					element={<AuthorizedRoute component={UnitList} />}
				/>
				<Route
					path="/units/create"
					element={<AuthorizedRoute component={CreateUnit} />}
				/>
				<Route
					path="/units/:id/edit"
					element={<AuthorizedRoute component={CreateUnit} />}
				/>
				<Route
					path="/users"
					element={<AuthorizedRoute component={UsersList} />}
				/>
				<Route
					path="/users/create"
					element={<AuthorizedRoute component={UserEdit} />}
				/>
				<Route
					path="/users/:id/edit"
					element={<AuthorizedRoute component={UserEdit} />}
				/>
				<Route
					path="/transactions"
					element={<AuthorizedRoute component={Transactions} />}
				/>
				<Route
					path="/transactions/create"
					element={<AuthorizedRoute component={InvoiceEdit} />}
				/>
				<Route
					path="/transactions/:id/edit"
					element={<AuthorizedRoute component={InvoiceEdit} />}
				/>
			</Routes>
		</Router>
	);
};

export default AppRouter;
