import { createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../slices/NotificationSlice/GlobalNotificationSlice";
import { axiosInstance } from "./api";
// import { bootstrapAction } from "../../../../actions/bootstrap";

export const getInvoices = createAsyncThunk("invoices", async (props) => {
	const response = await axiosInstance.get("/invoices", {
		params: { ...props },
	});
	return response.data;
});

export const createInvoice = createAsyncThunk(
	"createInvoice",
	async (props, { dispatch }) => {
		try {
			const response = await axiosInstance.post("/invoices", props);
			dispatch(
				addMessage({
					type: "success",
					title: "Transankcija dodata",
					visibilityTime: 5000,
				})
			);
			return response.data;
		} catch (e) {
			const { response } = e;
			const { message } = response.data;
			dispatch(
				addMessage({
					type: "error",
					title: "Transankcija nije dodata",
					message: message,
					visibilityTime: 5000,
				})
			);
		}
	}
);

export const updateInvoice = createAsyncThunk(
	"updateInvoice",
	async ({ id, payload }, { dispatch }) => {
		const response = await axiosInstance.put(`/invoices/${id}`, payload);
		return response.data;
	}
);

export const deleteInvoice = createAsyncThunk(
	"deleteInvoice",
	async (id, { dispatch }) => {
		await axiosInstance.delete(`/invoices/${id}`);
		return id;
	}
);
