import * as yup from "yup";
export const userLoginSchema = yup.object().shape({
	username: yup.string().required(),
	password: yup.string().required(),
});

export const userCreateSchema = yup.object().shape({
	username: yup.string().required(),
	email: yup.string().email("Wrong email format"),
	type: yup.number().required(),
	unit_id: yup.number().required(),
	password: yup.string().when("type", {
		is: (type) => type === 1 || type === 2,
		then: yup.string().required(),
	}),
	confirm_password: yup.string().when("type", {
		is: (type) => type === 1 || type === 2,
		then: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match"),
	}),
});

export const userEditSchema = yup.object().shape({
	username: yup.string().required(),
	email: yup.string().email("Wrong email format"),
	type: yup.number().required(),
});
