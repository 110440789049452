import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInvoices } from "./Invoice";

import { getUnits } from "./Units";
import { getUsers } from "./UserActions";
// import { bootstrapAction } from "../../../../actions/bootstrap";

export const bootstrapActions = createAsyncThunk(
	"bootstrap",
	async (props, { dispatch }) => {
		dispatch(getUsers());
		dispatch(getUnits());
		dispatch(getInvoices());
	}
);
