import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { invoiceSlice } from "./slices/InvoiceSlice";
import { globalNotificationSlice } from "./slices/NotificationSlice/GlobalNotificationSlice";
import { unitSlice } from "./slices/UnitSlice";
import { userSlice } from "./slices/UserSlice";

const combinedReducer = combineReducers({
	user: userSlice.reducer,
	unit: unitSlice.reducer,
	invoice: invoiceSlice.reducer,
	globalNotifications: globalNotificationSlice.reducer,
});

const rootReducer = (state, action) => {
	if (action.type === "logout/fulfilled") {
		// eslint-disable-next-line no-param-reassign
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
});
