import { createSlice } from "@reduxjs/toolkit";
import { getMe, logIn, logOut } from "../services/user/LoginActions";
import {
	createUser,
	deleteUser,
	editUser,
	getUsers,
} from "../services/UserActions";

const SLICE_NAME = "user";
const storageToken = localStorage.getItem("token");
const initialState = {
	loading: false,
	id: null,
	token: storageToken || null,
	last_name: "",
	first_name: "",
	username: "",
	email: "",
	users: [],
	totalUsers: 0,
	unit: null,
	unit_id: null,
	vip_card_number: null,
	discount: null,
	filter: {
		q: {},
	},
};

export const userSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[getMe.pending]: (state) => {
			state.loading = true;
		},
		[getMe.fulfilled]: (state, { payload }) => {
			state.loading = false;

			state.id = payload.id;
			state.first_name = payload.first_name;
			state.last_name = payload.last_name;
			state.username = payload.username;
			state.email = payload.email;
			state.type = payload.type;
			state.unit_id = payload.unit_id;
			state.location = payload.location;
			state.discount = payload.discount;
		},
		[logIn.pending]: (state) => {
			state.loading = true;
			state.loginError = false;
		},
		[logIn.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.token = payload.token;
			localStorage.setItem("token", payload.token);
		},
		[logIn.rejected]: (state) => {
			state.loading = false;
			state.loginError = true;
		},
		[logOut.pending]: (state) => {
			state.loading = true;
		},
		[logOut.fulfilled]: (state) => {
			state = initialState;
			localStorage.removeItem("token");
		},
		[getUsers.fulfilled]: (state, { payload }) => {
			state.users = payload.data;
			state.totalUsers = payload.total;
		},
		[createUser.pending]: (state) => {
			state.loading = true;
		},
		[createUser.fulfilled]: (state, { payload }) => {
			if (payload) {
				state.users.push(payload);
			}
			state.loading = false;
		},
		[editUser.pending]: (state) => {
			state.loading = true;
		},
		[editUser.fulfilled]: (state, { payload }) => {
			state.loading = false;
			const index = state.users.findIndex((user) => user.id === payload.id);
			if (index !== -1) {
				state.users[index] = payload;
			}
		},
		[deleteUser.pending]: (state) => {
			state.loading = true;
		},
		[deleteUser.fulfilled]: (state, { payload }) => {
			state.users = state.users.filter((user) => user.id !== parseInt(payload));
			state.loading = false;
		},
	},
});

export const {
	setFilter,
	setRoleFilter,
	setCompanyFilter,
	setStatusFilter,
	setLastActiveFilter,
	emptySearchField,
	setSelectedUser,
	clearFilter,
	setIsInfo,
	setEditModalOpen,
	setDeleteModalOpen,
	clearFormErrors,
	setUpdateUser,
	clearPasswordReset,
} = userSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const userSliceSelectors = {
	loading: (rootState) => {
		const appState = getAppState(rootState);
		return appState.loading;
	},
	defaultUserFields: (rootState) => {
		const appState = getAppState(rootState);
		return {
			id: appState.id,
			first_name: appState.first_name,
			last_name: appState.last_name,
			type: appState.type,
			username: appState.username,
			email: appState.email,
			is_activated: appState.is_activated,
			password_changed_at: appState.password_changed_at,
			created_at: appState.created_at,
			roles: appState.roles,
			unit: appState.unit,
			unit_id: appState.unit_id,
			location: appState.location,
			discount: appState.discount,
		};
	},
	loginError: (rootState) => {
		const appState = getAppState(rootState);
		return appState.loginError;
	},
	users: (rootState) => {
		const appState = getAppState(rootState);
		return appState.users;
	},
	usersLoading: (rootState) => {
		const appState = getAppState(rootState);
		return appState.usersLoading;
	},
	selectedUser: (rootState) => {
		const appState = getAppState(rootState);
		return appState.selectedUser;
	},
	isInfo: (rootState) => {
		const appState = getAppState(rootState);
		return appState.isInfo;
	},
	filter: (rootState) => {
		const appState = getAppState(rootState);
		return appState.filter;
	},
	editModalOpen: (rootState) => {
		const appState = getAppState(rootState);
		return appState.editModalOpen;
	},
	deleteModalOpen: (rootState) => {
		const appState = getAppState(rootState);
		return appState.deleteModalOpen;
	},
	token: (rootState) => {
		const appState = getAppState(rootState);
		return appState.token;
	},
	type: (rootState) => {
		const appState = getAppState(rootState);
		return appState.type || null;
	},
	formErrors: (rootState) => {
		const appState = getAppState(rootState);
		return appState.formErrors;
	},
	updateUser: (rootState) => {
		const appState = getAppState(rootState);
		return appState.updateUser;
	},
	passwordReset: (rootState) => {
		const appState = getAppState(rootState);
		return appState.passwordReset;
	},
	totalUsers: (rootState) => {
		const appState = getAppState(rootState);
		return appState.totalUsers;
	},
};
