import { yupResolver } from "@hookform/resolvers/yup";
import {
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { useParams, useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createUnit, updateUnit } from "../../services/Units";
import { unitSliceSelectors } from "../../slices/UnitSlice";
import { unitCreatSchema } from "../../validation/unitSchema";

const CreateUnit = (props) => {
	const navigate = useNavigate();
	const units = useSelector(unitSliceSelectors.units);
	const [unit, setUnit] = useState(null);
	const { id } = useParams();
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(unitCreatSchema),
	});

	useEffect(() => {
		if (id && !unit && units) {
			const foundUnit = units.find((unit) => unit.id === parseInt(id));
			if (foundUnit) {
				setUnit(foundUnit);
				reset(foundUnit);
			}
		}
	}, [unit, id, units, reset]);

	const onSubmit = (values) => {
		if (id) {
			dispatch(updateUnit({ id, payload: values })).then(() => {
				navigate("/units");
			});
		} else {
			dispatch(createUnit(values)).then(() => {
				navigate("/units");
			});
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div
				style={{
					padding: 30,
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					display: "flex",
					height: "auto",
					paddingTop: 100,
				}}>
				<Grid style={{ marginBottom: 50 }}>
					<Typography variant="h4" color="primary">
						Kreiraj Poslovnicu
					</Typography>
				</Grid>

				<Paper
					variant="outlined"
					style={{ width: 600, paddingTop: 30, paddingBottom: 30 }}>
					<Grid
						container
						spacing={3}
						direction={"column"}
						justifyContent={"center"}
						alignItems={"center"}>
						<Grid item xs={12}>
							<Box>
								<TextField
									{...register("name")}
									label="Naziv"
									error={Boolean(errors.name)}
								/>
								{errors.name && (
									<Typography color="error" variant="subtitle2">
										{errors.name.message}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box>
								<TextField
									{...register("location")}
									label="Lokacija"
									error={Boolean(errors.location)}
								/>
								{errors.location && (
									<Typography color="error" variant="subtitle2">
										{errors.location.message}
									</Typography>
								)}
							</Box>
						</Grid>

						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								color="primary"
								variant="outlined">
								Save
							</Button>
							<Button
								onClick={() => {
									navigate(`/units`);
								}}
								fullWidth
								variant="outlined">
								Back
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</div>
		</form>
	);
};
export default CreateUnit;
