import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

export default function CustomTablePagination({
	handlePageChange,
	numberOfRows,
}) {
	const [page, setPage] = React.useState(0);
	const rowsPerPage = 25;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		handlePageChange(newPage);
	};

	return (
		<TablePagination
			component="div"
			count={numberOfRows}
			page={page}
			onPageChange={handleChangePage}
			rowsPerPage={rowsPerPage}
			rowsPerPageOptions={[]}
		/>
	);
}
