import * as React from "react";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";

const UnitFilter = ({ handleSearchChange, style }) => {
	return (
		<Paper variant="outlined" style={{ padding: 20, marginTop: 36.5 }}>
			<Typography variant="h6" style={{ marginBottom: 5 }}>
				Pretraga
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						label={"Pretraga poslovnica"}
						variant="outlined"
						helperText={"Pretraga poslovnica po imenu i lokaciji"}
						fullWidth
						size="medium"
						style={style}
						onChange={handleSearchChange}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default UnitFilter;
