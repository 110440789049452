import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./api";
// import { bootstrapAction } from "../../../../actions/bootstrap";

export const getUnits = createAsyncThunk(
	"units",
	async (props, { dispatch }) => {
		const response = await axiosInstance.get("/units", {
			params: { ...props },
		});
		return response.data;
	}
);

export const createUnit = createAsyncThunk(
	"createUnit",
	async (props, { dispatch }) => {
		const response = await axiosInstance.post("/units", props);
		return response.data;
	}
);

export const updateUnit = createAsyncThunk(
	"updateUnit",
	async ({ id, payload }, { dispatch }) => {
		const response = await axiosInstance.put(`/units/${id}`, payload);
		return response.data;
	}
);

export const deleteUnit = createAsyncThunk(
	"deleteUnit",
	async (id, { dispatch }) => {
		await axiosInstance.delete(`/units/${id}`);
		return id;
	}
);
