import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
	globalNotificationSliceSelectors,
	deleteMessage,
} from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { GlobalToastNotificationItem } from "./GlobalToastNotificationItem";

const useStyles = makeStyles({
	notificationContainer: {
		position: "absolute",
		height: "auto",
		right: "2%",
		top: "2%",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
	},
});

export function GlobalToastNotificationsComponent() {
	const notifications = useSelector(
		globalNotificationSliceSelectors.notifications
	);
	const dispatch = useDispatch();
	const classes = useStyles();

	const closeNotification = (uuid) => {
		dispatch(deleteMessage(uuid));
	};

	return (
		<div className={classes.notificationContainer}>
			{notifications.map((notif) => (
				<GlobalToastNotificationItem
					onClose={closeNotification}
					notification={notif}
					key={notif.uuid}
				/>
			))}
		</div>
	);
}
