import * as React from "react";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";

const UserFilter = ({
	handleSearchChange,
	handleSearchChangeByVipCard,
	userType,
}) => {
	return (
		<Paper variant="outlined" style={{ padding: 20, marginTop: 36.5 }}>
			<Typography variant="h6" style={{ marginBottom: 5 }}>
				Pretraga
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						label={"Pretraga korisnika"}
						variant="outlined"
						helperText={
							"Pretraga korisnika po imenu, prezimenu, vip kartici, username-u"
						}
						size="medium"
						fullWidth
						onChange={handleSearchChange}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default UserFilter;
