/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import {
	Business,
	ExitToApp,
	People,
	Person,
	Settings,
	Store,
} from "@material-ui/icons";
import { useNavigate, useLocation } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../services/user/LoginActions";
import { userSliceSelectors } from "../slices/UserSlice";
import { bootstrapActions } from "../services/BootstrapActions";

export default function Menu() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(userSliceSelectors.defaultUserFields);
	const isUserLoading = useSelector(userSliceSelectors.loading);
	const location = useLocation();
	const { pathname } = location;
	useEffect(() => {
		if (user && !isUserLoading) {
			dispatch(bootstrapActions());
		}
	}, []);
	return (
		<>
			<List>
				<ListItem
					button
					key={"users"}
					selected={pathname.indexOf("users") !== -1 || pathname === "/"}
					onClick={() => {
						navigate("/");
					}}>
					<ListItemIcon>
						<People />
					</ListItemIcon>
					<ListItemText primary={"Korisnici"} />
				</ListItem>
				{user.type === 1 ? (
					<ListItem
						button
						key={"units"}
						selected={pathname.indexOf("units") !== -1}
						onClick={() => {
							navigate("/units");
						}}>
						<ListItemIcon>
							<Business />
						</ListItemIcon>
						<ListItemText primary={"Poslovnice"} />
					</ListItem>
				) : null}
				<ListItem
					button
					key={"transctions_by_vip_card"}
					selected={pathname.indexOf("/transactions") !== -1}
					onClick={() => {
						navigate("/transactions");
					}}>
					<ListItemIcon>
						<Store />
					</ListItemIcon>
					<ListItemText primary={"Promet"} />
				</ListItem>
				{/* <ListItem
					button
					key={"transctions_by_store"}
					selected={pathname.indexOf("/transactions/by-unit") !== -1}
					onClick={() => {
						navigate("/transactions/by-unit");
					}}>
					<ListItemIcon>
						<Store />
					</ListItemIcon>
					<ListItemText primary={"Promet po poslovnoj jedinici"} />
				</ListItem>
				<ListItem
					button
					key={"transctions_by_salesman"}
					selected={pathname.indexOf("/transactions/by-seller") !== -1}
					onClick={() => {
						navigate("/transactions/by-seller");
					}}>
					<ListItemIcon>
						<Person />
					</ListItemIcon>
					<ListItemText primary={"Promet po prodavcu"} />
				</ListItem>
				<ListItem
					button
					key={"transctions_balance"}
					selected={pathname.indexOf("/transactions/balance") !== -1}
					onClick={() => {
						navigate("/transactions/balance");
					}}>
					<ListItemIcon>
						<Loyalty />
					</ListItemIcon>
					<ListItemText primary={"Balans vip kartica"} />
				</ListItem> */}
			</List>
			<Divider />
			<List>
				<ListItem button key={"user"}>
					<ListItemIcon>
						<Person />
					</ListItemIcon>
					<ListItemText primary={`${user.first_name} ${user.last_name}`} />
				</ListItem>
				<ListItem button key={"settings"}>
					<ListItemIcon>
						<Settings />
					</ListItemIcon>
					<ListItemText primary={"Podesavanja"} />
				</ListItem>
				<ListItem
					button
					key={"logout"}
					onClick={() => {
						dispatch(logOut());
					}}>
					<ListItemIcon>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText primary={"Izloguj se"} />
				</ListItem>
			</List>
		</>
	);
}
