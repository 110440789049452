import * as React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { userSliceSelectors } from "../../slices/UserSlice";
import { getUsers } from "../../services/UserActions";

const UserInputField = ({
	hanldeChange,
	userType,
	title,
	style,
	isInvoice,
}) => {
	const dispatch = useDispatch();
	const users = useSelector(userSliceSelectors.users);

	const handleUserInputChange = async (e) => {
		let query = {
			q: {
				name: e.target.value,
			},
		};

		if (userType) {
			query["type"] = userType;
		}

		dispatch(getUsers(query));
	};

	const handleUserSelectedChange = (e, value) => {
		if (!value) {
			return;
		}
		hanldeChange(value.id);
	};

	return (
		<Autocomplete
			id="combo-box-demo"
			options={users.filter((user) => user.type === userType)}
			getOptionLabel={(option) => {
				if (isInvoice) {
					return `${option.first_name}  ${
						option.last_name +
						(userType === 3
							? "(" +
							  option.vip_card_number +
							  ") Balance: " +
							  Math.round(option.vip_card_balance) +
							  " RSD"
							: "")
					}`;
				} else {
					return `${option.first_name}  ${
						option.last_name +
						(userType === 3 ? "(" + option.vip_card_number + ")" : "")
					}`;
				}
			}}
			onInputChange={handleUserInputChange}
			onChange={handleUserSelectedChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label={title}
					variant="outlined"
					style={style}
					fullWidth
					size="medium"
				/>
			)}
		/>
	);
};

export default UserInputField;
