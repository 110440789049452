import { createSlice } from "@reduxjs/toolkit";
import {
	createInvoice,
	deleteInvoice,
	getInvoices,
	updateInvoice,
} from "../services/Invoice";

const SLICE_NAME = "invoice";
const initialState = {
	loading: false,
	unit: {
		name: null,
		location: null,
	},
	totalInvoices: 0,
	invoices: [],
	filter: {
		q: {},
	},
};

export const invoiceSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[getInvoices.pending]: (state) => {
			state.loading = true;
		},
		[getInvoices.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.invoices = payload.data;
			state.totalInvoices = payload.total;
		},
		[createInvoice.pending]: (state) => {
			state.loading = true;
		},
		[createInvoice.fulfilled]: (state, { payload }) => {},

		[updateInvoice.pending]: (state) => {
			state.loading = true;
		},
		[updateInvoice.fulfilled]: (state, { payload }) => {},
		[deleteInvoice.pending]: (state) => {
			state.loading = true;
		},
		[deleteInvoice.fulfilled]: (state, { payload }) => {
			state.invoices = state.invoices.filter(
				(unit) => unit.id !== parseInt(payload)
			);
		},
	},
});

export const {
	setFilter,
	setRoleFilter,
	setCompanyFilter,
	setStatusFilter,
	setLastActiveFilter,
	emptySearchField,
	setSelectedUser,
	clearFilter,
	setIsInfo,
	setEditModalOpen,
	setDeleteModalOpen,
	clearFormErrors,
	setUpdateUser,
	clearPasswordReset,
} = invoiceSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const invoiceSliceSelectors = {
	loading: (rootState) => {
		const appState = getAppState(rootState);
		return appState.loading;
	},
	invoices: (rootState) => {
		const appState = getAppState(rootState);
		return appState.invoices;
	},
	totalInvoices: (rootState) => {
		const appState = getAppState(rootState);
		return appState.totalInvoices;
	},
};
