import "./App.css";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import AppRouter from "./components/Router";
import { Provider } from "react-redux";
import { store } from "./store";
import { light } from "@material-ui/core/styles/createPalette";

function App() {
	const theme = createTheme({
		theme: light,
	});
	return (
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<AppRouter />
			</MuiThemeProvider>
		</Provider>
	);
}

export default App;
